export enum ProjectType {
  Residential = 'Residential',
  Commercial = 'Commercial',
  Civil = 'Civil',
}

export enum Permissions {
  Self = 'Self',
  Outsource = 'Outsource',
}

export enum BuildingModel {
  Duplex = 'Duplex',
  Individual = 'Individual',
  Portions = 'Portions',
  Banglaw = 'Banglaw',
  Villa = 'Villa',
  Shops = 'Shops',
  Complex = 'Complex',
  Slabs = 'Slabs',
}

export interface IStructural {
  floors: number;
  load: string;
  type: string;
  elevation: string;
}

export interface IPlotSize {
  length: number;
  width: number;
}

export interface ISize {
  east: string;
  west: string;
  north: string;
  south: string;
}

export interface IItemStatus {
  progress: number;
  startDate?: Date;
  duration?: number;
  displayOrder: number;
}

export interface IProjectPhase {
  name: string;
  totalEstimatedAmount: number;
  totalPaymentReceived?: number;
  hasStarted?: boolean;
  startDate?: string;
  completionDate?: string;
}

export interface IProjectBalanceStatus {
  isBalanceOverdue?: boolean;
  totalBalanceOverdue?: number;
  phase?: string;
}

export interface IProjectStatus {
  planStartDate?: Date;
  totalPaymentReceived?: number;
  totalProjectProgress?: number;
  balanceStatus?: IProjectBalanceStatus;
  activities: Record<string, IItemStatus>;
}

export const DEFAULT_ACTIVITY_STATUS: IItemStatus = {
  progress: 0,
  startDate: new Date(),
  displayOrder: 0,
};

export const DEFAULT_PROJECT_STATUS: IProjectStatus = {
  activities: {
    permissions: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 1 },
    drawings: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 2 },
    earthWorks: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 3 },
    rcc: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 4 },
    brickWork: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 5 },
    plastering: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 6 },
    plumbing: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 7 },
    electricals: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 8 },
    painting: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 9 },
    flooring: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 10 },
    doors: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 11 },
    windows: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 12 },
    msWorks: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 13 },
    ssWorks: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 14 },
    glassWorks: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 15 },
    sanitory: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 16 },
    testings: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 17 },
    falseCeiling: { ...DEFAULT_ACTIVITY_STATUS, displayOrder: 18 },
  },
};

export interface IProjectPaymentDetails {
  totalEstimatedCost?: number;
  totalPaymentReceived?: number;
  balanceStatus?: IProjectBalanceStatus;
  phases?: IProjectPhase[];
  updatedOn: string;
  updatedBy: string;
}

export interface IProject {
  crn: string;
  fileNumber: string;
  clientName: string;
  location?: string;
  totalEstimatedCost?: number;
  projectType: ProjectType;
  permissions: Permissions;
  buildingModel: BuildingModel;
  floors?: string;
  provisions?: string;
  structural: IStructural;
  plotSize: IPlotSize;
  slabSize: IPlotSize;
  facing: string;
  roadSize: ISize;
  setBacks: ISize;
  projectStarted: boolean;
  projectStatus?: IProjectStatus;
  userId?: string;
  createdOn?: string;
  createdBy?: string;
  updatedOn?: string;
  updatedBy?: string;
}

export interface IUser {
  userName: string;
  sub: string;
  createdDate: string;
  lastLogin?: string;
  status: string;
  enabled: boolean;
}
