import { Header } from '../../../../layouts';

export const UserHeader = () => {
  return (
    <Header>
      {/* <Link to="profile">Profile</Link> */}
      <></>
    </Header>
  );
};
