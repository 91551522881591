/* eslint-disable react/no-unknown-property */
import { FILE_NUMBER_FORMAT } from '@agsnirman/core/constants';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import { Spinner } from '../../../../components';
import {
  Button,
  Error,
  FormField,
  FormInput,
  FormLabel,
} from '../../../../styles';

interface DialogProps {
  isOpen: boolean;
  isLoading: boolean;
  onConfirmClick: (fileNumber: string, crn?: string) => void;
  onCancelClick: () => void;
  validationError?: boolean;
  originalFileNumber: string;
}

export const DummyCtrl = tw`
bg-white`;

const Field = tw(FormField)`
  flex
  flex-col
  items-start
`;

export const CloneDialog: React.FC<DialogProps> = ({
  isOpen,
  isLoading,
  onConfirmClick,
  onCancelClick,
  validationError,
  originalFileNumber,
}) => {
  const [fileNumber, setFileNumber] = useState('');
  const [crn, setCRN] = useState('');
  useEffect(() => {
    if (!isOpen) {
      setFileNumber('');
      setCRN('');
    }
  }, [isOpen]);
  return (
    <>
      {isOpen ? (
        <>
          <div tw="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div tw="relative w-auto my-6 mx-auto min-w-[30rem]">
              {/*content*/}

              <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div tw="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  {/* border-slate-200 */}
                  <h3 tw="text-2xl font-semibold">Clone Project</h3>
                  <button
                    tw="p-1 ml-auto border-0 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={onCancelClick}
                  >
                    <span tw="opacity-20 h-6 w-6 text-xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div tw="relative p-6 flex-auto">
                  {/* text-slate-500 */}
                  <Field>
                    <FormLabel>Cloning from File Number</FormLabel>
                    <FormInput
                      type="text"
                      disabled
                      value={originalFileNumber}
                    />
                  </Field>
                  <Field>
                    <FormLabel>New File Number</FormLabel>
                    <FormInput
                      type="text"
                      disabled={isLoading}
                      onChange={(e) =>
                        // setIsConfirmDisabled(e.target.value !== fileNumber)
                        setFileNumber(e.target.value)
                      }
                    />
                    {validationError && (
                      <Error>
                        Another project with the same file number exists
                      </Error>
                    )}
                  </Field>
                  <Field>
                    <FormLabel>New CRN</FormLabel>
                    <FormInput
                      type="text"
                      disabled={isLoading}
                      onChange={(e) =>
                        // setIsConfirmDisabled(e.target.value !== fileNumber)
                        setCRN(e.target.value)
                      }
                    />
                  </Field>
                </div>
                {/*footer*/}
                {/* border-slate-200 */}
                <div tw="flex items-center justify-end p-6 border-t border-solid rounded-b">
                  <button
                    tw="font-semibold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onCancelClick}
                    disabled={isLoading}
                  >
                    Close
                  </button>
                  <Button
                    onClick={() => onConfirmClick(fileNumber, crn)}
                    disabled={
                      !FILE_NUMBER_FORMAT.test(fileNumber) || crn.length < 6
                    }
                  >
                    CONFIRM
                    {isLoading && <Spinner />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div tw="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}{' '}
    </>
  );
};
