import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';

import { ContainerFlex } from '../../../styles';

const TabContainer = styled.div`
  ${tw`
    flex 
    space-x-3 
    border-b-4
    border-rosewood-light
    overflow-auto
  `}
  ::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;

const TabButton = styled.button<{ isActive: boolean }>(() => [
  tw`
      py-2
      px-4
      border-b-2 
      transition-colors 
      duration-300
      border-transparent
      flex
      justify-center
      items-center
      gap-3
      hover:bg-rosewood-light
      hover:text-white
      rounded-t-md
    `,
  ({ isActive }) =>
    isActive &&
    tw`border-rosewood-light font-bold bg-rosewood-light text-white`,
]);

const TabContent = tw.div`
    py-4
`;

export interface ITabTitle {
  title: string;
  icon: React.ReactElement;
  isDisabled?: boolean;
}

interface ITabsProps {
  titles: ITabTitle[];
  children: React.ReactElement[];
}

export const Tabs: React.FC<ITabsProps> = ({ titles, children }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <TabContainer>
        {/* Loop through tab data and render button for each. */}
        {titles.map((tab, idx) => {
          return (
            <ContainerFlex direction="row" key={tab.title}>
              <TabButton
                isActive={idx === activeTabIndex}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
                disabled={tab.isDisabled}
              >
                {tab.icon}
                {tab.title}
              </TabButton>
            </ContainerFlex>
          );
        })}
      </TabContainer>
      {/* Show active tab content. */}
      <TabContent>
        <p>{children[activeTabIndex]}</p>
      </TabContent>
    </>
  );
};
