import React from 'react';
import ReactSlider from 'react-slider';
import { styled } from 'twin.macro';

const Container = styled.div`
  --main-color: #620000;
  --main-color-hover: #ffdada;
  --track-color: #ddd;
  --mark-color: #aaaaaa;

  flex: 1;

  .customSlider {
    max-width: 14rem;
    top: -1rem; // TODO: Revisit this
    margin-left: 5rem;
  }

  .customSlider-track {
    top: 12px;
    height: 6px;
    background: var(--track-color);
    border-radius: 4px;
  }

  .customSlider-track.customSlider-track-0 {
    background: var(--main-color);
  }

  .customSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--main-color);
    /* background-color: #620000; */
    color: #fff;
    /* shape of the thumb: circle */
    width: 30px;
    height: 30px;
    /* padding: 4px; */
    border-radius: 50%;
    /* remove default outline when selected */
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: auto !important;
  }

  .customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
  }

  .customSlider-mark {
    cursor: pointer;
    top: 11px;
    width: 1.5px;
    height: 8px;
    background-color: var(--mark-color);
  }

  .customSlider-mark.customSlider-mark-before {
    background-color: var(--main-color);
  }

  .customSlider-mark.customSlider-mark-active {
    display: none;
  }
`;

interface ISliderProps {
  value?: number;
  onChange?: ((value: number, index: number) => void) | undefined;
}

export const Slider: React.FC<ISliderProps> = ({ value, onChange }) => {
  return (
    <Container>
      <ReactSlider
        className="customSlider"
        thumbClassName="customSlider-thumb"
        trackClassName="customSlider-track"
        markClassName="customSlider-mark"
        min={0}
        max={100}
        defaultValue={40}
        value={value}
        onChange={onChange}
        renderMark={(props) => {
          if (props.key && props.key < value!) {
            props.className = 'customSlider-mark customSlider-mark-before';
          } else if (props.key === value) {
            props.className = 'customSlider-mark customSlider-mark-active';
          }
          return <span {...props} />;
        }}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
      />
    </Container>
  );
};
