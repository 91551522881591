/* eslint-disable react/no-unknown-property */
import { useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import tw from 'twin.macro';

import { Spinner } from '../../../../components';
import { Button, FormInput } from '../../../../styles';
interface DialogProps {
  isOpen: boolean;
  isLoading: boolean;
  onConfirmClick: (values: any) => void;
  onCancelClick: () => void;
  fileNumber?: string;
  title?: string;
  isDanger?: boolean;
}

const DialogBackground = tw.div`
  opacity-25 
  fixed 
  inset-0 
  z-40 
  bg-black
`;

export const ConfirmDialog: React.FC<DialogProps> = ({
  isOpen,
  isLoading,
  onConfirmClick,
  onCancelClick,
  fileNumber,
  title,
  isDanger,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  return (
    <>
      {isOpen ? (
        <>
          <div tw="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div tw="relative w-auto my-6 mx-auto max-w-xl">
              {/*content*/}
              <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div tw="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  {/* border-slate-200 */}
                  <h3 tw="text-2xl font-semibold">
                    {title || 'Confirm action'}
                  </h3>
                  <button
                    tw="p-1 ml-auto border-0 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={onCancelClick}
                  >
                    <span tw="opacity-20 h-6 w-6 text-xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div tw="relative p-6 flex-auto">
                  {/* text-slate-500 */}
                  <p tw="my-4 leading-relaxed text-left">
                    This action will affect what users see. Please ensure the
                    details are correct and type the project{' '}
                    <span tw="border-0 border-solid border-b-2 border-rosewood-200">
                      file number
                    </span>{' '}
                    in the textbox below before continuing.
                  </p>
                  <FormInput
                    type="text"
                    disabled={isLoading}
                    onChange={(e) =>
                      setIsConfirmDisabled(e.target.value !== fileNumber)
                    }
                  />
                </div>
                {/*footer*/}
                {/* border-slate-200 */}
                {isDanger && (
                  <p tw="flex items-center mt-2 justify-center bg-rosewood-200">
                    Proceed with caution. This action cannot be undone{' '}
                    <FiAlertTriangle tw="ml-1" />
                  </p>
                )}
                <div tw="flex items-center justify-end p-6 border-t border-solid rounded-b">
                  <button
                    tw="font-semibold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onCancelClick}
                    disabled={isLoading}
                  >
                    Close
                  </button>
                  <Button onClick={onConfirmClick} disabled={isConfirmDisabled}>
                    CONFIRM
                    {isLoading && <Spinner />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DialogBackground tw="opacity-25 fixed inset-0 z-40 bg-black"></DialogBackground>
        </>
      ) : null}{' '}
    </>
  );
};
