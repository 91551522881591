import { IProject } from '@agsnirman/core/types';
import { lazy, Suspense } from 'react';
import { FiCamera, FiFile, FiInfo, FiTrendingUp } from 'react-icons/fi';
import tw, { styled } from 'twin.macro';

import { BreadCrumb, ICrumb } from '../bread-crumb';
import { ITabTitle, Tabs } from '../common';
import { ProjectDocuments } from './documents';
import { ProjectSnapshot } from './snapshot';

const ProjectGallery = lazy(() => import('./gallery'));
const ProjectGantt = lazy(() => import('./gantt'));

const Container = styled.div`
  ${tw`
    mx-auto
    overflow-x-hidden
    md:overflow-auto
`}
`;

const tabTitles: ITabTitle[] = [
  {
    title: 'Status',
    icon: <FiInfo size={'1.25rem'} />,
  },
  {
    title: 'Documents',
    icon: <FiFile size={'1.25rem'} />,
  },
  {
    title: 'Gallery',
    icon: <FiCamera size={'1.25rem'} />,
  },
  {
    title: 'Gantt',
    icon: <FiTrendingUp size={'1.25rem'} />,
  },
];

export const ProjectHome: React.FC<{
  crumbs: ICrumb[];
  project?: IProject;
}> = ({ crumbs, project }) => {
  return (
    <Container>
      <BreadCrumb crumbs={crumbs} />
      <Tabs titles={tabTitles}>
        <ProjectSnapshot project={project} />
        <ProjectDocuments />
        <Suspense fallback={<>Loading...</>}>
          <ProjectGallery />
        </Suspense>
        <Suspense fallback={<>Loading...</>}>
          <ProjectGantt project={project} />
        </Suspense>
      </Tabs>
    </Container>
  );
};
