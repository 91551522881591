import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { Colors, Theme } from 'react-select';
import tw from 'twin.macro';

import {
  useGetProjectsQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from '~/store/apiSlice';

import { BreadCrumb, Spinner } from '../../components';
import { ISelectOption } from '../../models/selectOption';
import {
  ActionButton,
  ContainerFlex,
  FormContainer,
  FormField,
  FormInput,
  FormLabel,
  StyledH1,
} from '../../styles';
import { ConfirmDialog } from './components';

const StyledSelect = tw(Select)`
    flex-1
`;

const selectThemeFn = (theme: Theme) =>
  ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#ffdada',
      primary: '#620000',
    } as Colors,
    spacing: {
      baseUnit: 6,
    },
  } as Theme);

export const ProjectUserLink = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: projects } = useGetProjectsQuery();
  const { data: users, isLoading: usersLoading } = useGetUsersQuery();
  const [isOpen, setIsOpen] = useState(false);

  const [updateUser, { isLoading, isError, isSuccess, error }] =
    useUpdateUserMutation();

  const userSelectOptions = users?.map(
    (k) =>
      ({
        value: k.userName,
        label: k.userName,
      } as ISelectOption)
  );

  const currentProject = projects?.Items.find(
    (p) => p.fileNumber === params.fileNumber
  );
  const [userSelected, setUserSelected] = useState(
    Boolean(currentProject?.userId)
  );

  const { handleSubmit, control, register, setValue } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [formValues, setFormValues] = useState<any>();

  const onSubmit = (values: any) => {
    setFormValues(values);
    setIsOpen(true);
  };

  const onConfirmUpdateUser = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateUser({
      fileNumber: params.fileNumber!,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      userId: formValues.userId,
      isLink: !currentProject?.userId,
    });
  };

  useEffect(() => {
    setValue(
      'userId',
      userSelectOptions?.find((s) => s.value === currentProject?.userId)?.value
    );

    if (isSuccess) {
      toast.success('Operation success');

      navigate('/admin/projects', { replace: true });
    }

    if (isError) {
      toast.error(`Operation failed \n\n ${JSON.stringify(error)}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigate, isError]);

  return (
    <>
      <BreadCrumb
        crumbs={[
          { name: 'Home', path: '/admin' },
          { name: 'View Projects', path: '/admin/projects' },
          { name: 'Update User', isCurrent: true },
        ]}
      />
      <FormContainer>
        <StyledH1>Update User</StyledH1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <FormLabel htmlFor="clientName">CRN</FormLabel>
            <FormInput
              type="text"
              id="crn"
              {...register('crn')}
              defaultValue={currentProject?.crn}
              disabled
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="clientName">File Number</FormLabel>
            <FormInput
              type="text"
              id="fileNumber"
              {...register('fileNumber')}
              defaultValue={currentProject?.fileNumber}
              disabled
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="clientName">Client Name</FormLabel>
            <FormInput
              type="text"
              id="clientName"
              {...register('clientName')}
              defaultValue={currentProject?.clientName}
              disabled
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="userId">User</FormLabel>
            <Controller
              name="userId"
              control={control}
              render={({ field: { onChange, name } }) => (
                <ContainerFlex direction="row">
                  <StyledSelect
                    defaultValue={userSelectOptions?.find(
                      (s) => s.value === currentProject?.userId
                    )}
                    name={name}
                    options={userSelectOptions}
                    onChange={(newValue, _) => {
                      onChange(newValue?.value);
                      setUserSelected(true);
                    }}
                    theme={selectThemeFn}
                    isDisabled={usersLoading || !!currentProject?.userId}
                  />
                  {usersLoading && <Spinner />}
                </ContainerFlex>
              )}
            />
          </FormField>
          <ActionButton type="submit" disabled={isLoading || !userSelected}>
            {currentProject?.userId ? 'Unlink' : 'Link'}
          </ActionButton>
        </form>
      </FormContainer>
      <ConfirmDialog
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
        onConfirmClick={onConfirmUpdateUser}
        isLoading={isLoading}
        fileNumber={currentProject?.fileNumber}
      />
    </>
  );
};
