import { BuildingModel, Permissions, ProjectType } from '@agsnirman/core/types';

import { ISelectOption } from '~/models/selectOption';

export const projectTypeSelectOptions = Object.keys(ProjectType).map(
  (k) =>
    ({
      value: k,
      label: k,
    } as ISelectOption)
);

export const permissionsSelectOptions = Object.keys(Permissions).map(
  (k) =>
    ({
      value: k,
      label: k,
    } as ISelectOption)
);

export const buildingModelSelectOptions = Object.keys(BuildingModel).map(
  (k) =>
    ({
      value: k,
      label: k,
    } as ISelectOption)
);
