import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

const configure = () => {
  Sentry.init({
    dsn: 'https://8f076f75ad5d43f6af5123e63a23a376@o4505105609326592.ingest.sentry.io/4505105614241792',
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT,
  });
};

/**
 * TODO
 * Set tags - User, Admin - to identify error easily
 * Set severity
 * Capture api errors and send to sentry
 */
const logError = (error: Error) => {
  Sentry.captureException(error);
};

const sentry = {
  configure,
  logError,
};

export { sentry };
