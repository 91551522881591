import { useParams } from 'react-router-dom';

import { ProjectHome } from '../../components';
import { useGetUserProjectsQuery } from '../../store';

export const UserProjectHome = () => {
  const { fileNumber } = useParams();

  const { data } = useGetUserProjectsQuery();
  const project = data?.find((p) => p.fileNumber === fileNumber);
  return (
    <ProjectHome
      crumbs={[
        { name: 'Home', path: '/user' },
        { name: fileNumber!, isCurrent: true },
      ]}
      project={project}
    />
  );
};
