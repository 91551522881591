/* eslint-disable react/no-unknown-property */
import { IconBaseProps } from 'react-icons';
import { ImSpinner8 } from 'react-icons/im';
import tw, { styled } from 'twin.macro';

const StyledSpinner = styled(ImSpinner8)`
  ${tw`
    animate-spin
    text-rosewood-900
    w-10
    h-10
    opacity-50
  `}
`;

const SpinnerContainer = tw.div`
    absolute
    right-1/2 
    bottom-1/2 
    transform 
    translate-x-1/2 
    translate-y-1/2
    flex
    flex-col
    gap-2
    items-center
    z-10
`;

interface SpinnerProps extends IconBaseProps {
  text?: string;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <>
      <SpinnerContainer>
        <StyledSpinner {...props} />
        {props.text && <div>{props.text}</div>}
      </SpinnerContainer>
      <div tw="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
