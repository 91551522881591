import dayjs from 'dayjs';
import tw from 'twin.macro';

import { ContainerFlex } from '../styles';

const FooterContainer = tw.footer`
    text-xs
    text-gray-400
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <ContainerFlex direction="col">
        <p>CIN: U45400TG2019PLC136125</p>
        <p>Registration Number: 136125</p>
        <p>&#174; AGS NIRMAN LIMITED {dayjs().format('YYYY')}</p>
      </ContainerFlex>
    </FooterContainer>
  );
};
