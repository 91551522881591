import { IUser } from '@agsnirman/core/types';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';

import { BreadCrumb, Spinner } from '../../components';
import { useGetUsersQuery } from '../../store';
import { ContainerFlex, Table, TableContainer } from '../../styles';

const columns: ColumnDef<IUser>[] = [
  {
    header: 'Username',
    accessorKey: 'userName',
  },
  {
    header: 'Created Date',
    accessorKey: 'createdDate',
    cell: (info) => (
      <ContainerFlex direction="row">
        {dayjs(info.getValue<string>()).format('DD MMM YYYY hh:mm A')}
      </ContainerFlex>
    ),
    size: 250,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    size: 250,
  },
  {
    header: 'Enabled',
    accessorKey: 'enabled',
  },
  {
    header: 'Last Login',
    accessorKey: 'lastLogin',
    cell: (info) => (
      <ContainerFlex direction="row">
        {info.getValue<string | undefined>() &&
          dayjs(info.getValue<string>()).format('DD MMM YYYY hh:mm A')}
      </ContainerFlex>
    ),
  },
];

const UsersTable: React.FC<{ data: IUser[] }> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export const ManageUsers = () => {
  const { data, error, isLoading, isFetching } = useGetUsersQuery();

  if (error) {
    console.log(error);
    return <h1>Error fetching users.</h1>;
  }

  return (
    <>
      <BreadCrumb
        crumbs={[
          { name: 'Home', path: '/admin' },
          { name: 'Manage Users', isCurrent: true },
        ]}
      />
      {isLoading || isFetching ? (
        <Spinner text="Fetching users" />
      ) : (
        data && <UsersTable data={data} />
      )}
    </>
  );
};
