/**
 * TODO: Revisit Chatwoot
 */
// import '../../scripts/liveChat';

import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { BiBuildingHouse } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { Spinner } from '../../components';
import { useAppDispatch } from '../../hooks';
import { IProps } from '../../models/props';
import { getSignedCookie, useGetUserProjectsQuery } from '../../store';
import {
  Card,
  Circle,
  ContainerFlex,
  ContainerGrid,
  H1,
  Inner,
  StatusText,
} from '../../styles';

const ErrorMessage = tw.h2`
text-red-500`;

const DashboardContainer: React.FC<IProps> = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  return (
    <>
      <H1>Welcome {user.username}</H1>
      {children}
    </>
  );
};

export const UserDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, isError } = useGetUserProjectsQuery();

  useEffect(() => {
    // TODO: Only trigger if the cookie isn't already set
    void (async () => {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        await dispatch(getSignedCookie(session.getIdToken().getJwtToken()));
      }
    })();

    // if (window.$chatwoot && window.$chatwoot.hasLoaded) {
    //   window.$chatwoot.toggleBubbleVisibility('show');
    //   window.$chatwoot.setUser('AGS001', {
    //     email: 'ags001@example.com',
    //     name: 'Vishnu-Client1',
    //   });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isFetching) {
    return (
      <DashboardContainer>
        <Spinner text="Fetching Projects" />
      </DashboardContainer>
    );
  }

  if (isError) {
    return (
      <DashboardContainer>
        <ErrorMessage>
          Something went wrong fetching projects. You can try refreshing the
          page to see if the issue is resolved. Please contact AGSNirman team if
          the issue persists.
        </ErrorMessage>
      </DashboardContainer>
    );
  }

  if (!data || data.length === 0) {
    return (
      <DashboardContainer>
        <h2>
          There are no projects linked to the current user. Please check back
          later.
        </h2>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <ContainerGrid>
        {data.map((d) => (
          <Card
            key={d.fileNumber}
            onClick={() => navigate(`project/${d.fileNumber}`)}
          >
            <ContainerFlex direction="col">
              <ContainerFlex direction="row" tw="self-start">
                {/* <StyledLink to="projects">{d.fileNumber}</StyledLink> */}
                <p>{d.fileNumber}</p>
                <BiBuildingHouse size={'1.5rem'} />
              </ContainerFlex>
              <ContainerFlex direction="row" tw="self-start">
                <StatusText>Total project progress: </StatusText>
                <Circle progress={d.projectStatus?.totalProjectProgress || 0}>
                  <Inner>{d.projectStatus?.totalProjectProgress || 0}%</Inner>
                </Circle>
              </ContainerFlex>
            </ContainerFlex>
          </Card>
        ))}
      </ContainerGrid>
    </DashboardContainer>
  );
};
