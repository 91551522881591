/* eslint-disable react/no-unknown-property */
import { FiInfo } from 'react-icons/fi';
import tw from 'twin.macro';

import { Button } from '../../../../styles';

interface DialogProps {
  isOpen: boolean;
  onCancelClick: () => void;
}
const DialogBackground = tw.div`
  opacity-25 
  fixed 
  inset-0 
  z-40 
  bg-black
`;

export const InfoDialog: React.FC<DialogProps> = ({
  isOpen,
  onCancelClick,
}) => {
  return (
    <>
      {isOpen ? (
        <>
          <div tw="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div tw="relative w-auto my-6 mx-auto max-w-xl">
              {/*content*/}
              <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div tw="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  {/* border-slate-200 */}
                  <h3 tw="text-2xl font-semibold flex justify-center items-center">
                    Information <FiInfo tw="ml-1" />
                  </h3>
                  <button
                    tw="p-1 ml-auto border-0 opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={onCancelClick}
                  >
                    <span tw="opacity-20 h-6 w-6 text-xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div tw="relative p-6 flex-auto">
                  {/* text-slate-500 */}
                  <p tw="my-4 leading-relaxed text-left">
                    Cannot delete a project if a user is linked to it. Please
                    unlink the user before proceeding.
                  </p>
                </div>
                {/*footer*/}
                {/* border-slate-200 */}
                <div tw="flex items-center justify-end p-6 border-t border-solid rounded-b">
                  <Button onClick={onCancelClick}>CLOSE</Button>
                </div>
              </div>
            </div>
          </div>
          <DialogBackground tw="opacity-25 fixed inset-0 z-40 bg-black"></DialogBackground>
        </>
      ) : null}{' '}
    </>
  );
};
