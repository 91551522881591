/* eslint-disable react/no-unknown-property */
import { IProject } from '@agsnirman/core/types';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FiCopy,
  FiEdit,
  FiTrash2,
  FiUserMinus,
  FiUserPlus,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { BreadCrumb, Spinner } from '../../components';
import { useCheckProject, useCognitoAuth } from '../../hooks';
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useGetUsersQuery,
} from '../../store';
import { ContainerFlex, Table, TableContainer } from '../../styles';
import { CloneDialog, ConfirmDialog, InfoDialog } from './components';

const EditLink = tw(Link)`
  border-0
  border-solid
  border-b-2
  border-rosewood-200
  whitespace-nowrap
`;

const columns: ColumnDef<IProject>[] = [
  {
    header: 'CRN',
    accessorKey: 'crn',
    size: 100,
  },
  {
    header: 'File Number',
    accessorKey: 'fileNumber',
    size: 100,
    cell: (info) => (
      <>
        <ContainerFlex direction="row">
          <EditLink to={`/admin/project/${info.getValue<string>()}/view`}>
            {info.getValue() as any}
          </EditLink>
          <FiEdit />
        </ContainerFlex>
        {/* <span tw="text-[0.6rem]" title="Updated On">
          {dayjs(info.row.getValue('updatedOn')).format('DD MMM YYYY hh:mmA')}
        </span> */}
        {/* <span>{info.row.getValue('updatedBy')}</span> */}
      </>
    ),
  },
  {
    header: 'Client Name',
    accessorKey: 'clientName',
  },
  {
    header: 'Project Status',
    size: 100,
    cell: (info) => (
      <ContainerFlex direction="row">
        <EditLink
          to={`/admin/project/${info.row.getValue<string>(
            'fileNumber'
          )}/status`}
        >
          Edit
        </EditLink>
        <FiEdit />
      </ContainerFlex>
    ),
  },
  {
    header: 'Progress',
    accessorKey: 'projectStarted',
    size: 60,
    cell: (info) => {
      return (
        <ContainerFlex direction="row">
          <EditLink
            to={`/admin/project/${info.row.getValue<string>('fileNumber')}`}
          >
            Show
          </EditLink>
        </ContainerFlex>
      );
    },
  },
  {
    header: 'Linked User',
    accessorKey: 'userId',
    cell: (info) => {
      return (
        <ContainerFlex direction="row">
          {info.row.original.userId ? (
            <>
              <EditLink
                to={`/admin/project/${info.row.getValue<string>(
                  'fileNumber'
                )}/link`}
              >
                {info.row.getValue<string>('userId')}
              </EditLink>
              <FiUserMinus />
            </>
          ) : (
            <>
              <EditLink
                to={`/admin/project/${info.row.getValue<string>(
                  'fileNumber'
                )}/link`}
              >
                Link
              </EditLink>
              <FiUserPlus />
            </>
          )}
        </ContainerFlex>
      );
    },
  },
  {
    header: 'Updated On',
    accessorKey: 'updatedOn',
    cell: (info) => (
      <ContainerFlex direction="row" tw="text-sm">
        {dayjs(info.getValue<string>()).format('DD MMM YYYY hh:mm A')}
      </ContainerFlex>
    ),
  },
  {
    header: 'Updated By',
    accessorKey: 'updatedBy',
  },
  {
    header: '',
    id: 'DeleteProject',
    accessorKey: 'fileNumber',
    size: 30,
    cell: (info) => <DeleteProject project={info.row.original} />,
  },
  {
    header: '',
    id: 'CloneProject',
    accessorKey: 'fileNumber',
    size: 30,
    cell: (info) => (
      <CloneProject
        key={info.row.getValue<string>('fileNumber')}
        project={info.row.original}
      />
    ),
  },
];

const DeleteProject: React.FC<{ project: IProject }> = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [
    deleteProject,
    {
      isLoading: isDeleteInProgress,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      error: deleteError,
    },
  ] = useDeleteProjectMutation();

  const onDeleteClick = () => {
    if (project.userId) {
      setIsInfoDialogOpen(true);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (isDeleteError) {
      toast.error(`Operation failed \n\n ${JSON.stringify(deleteError)}`);
    }
    if (isDeleteSuccess) {
      toast.success('Operation success');
    }
  }, [isDeleteSuccess, isDeleteError, deleteError]);

  return (
    <>
      <ContainerFlex direction="row">
        <FiTrash2
          title="Delete Project"
          color="red"
          style={{ cursor: 'pointer' }}
          onClick={onDeleteClick}
        />
      </ContainerFlex>
      <ConfirmDialog
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
        onConfirmClick={async () => {
          await deleteProject(project.fileNumber);
          setIsOpen(false);
        }}
        isLoading={isDeleteInProgress}
        title="Delete Project"
        fileNumber={project.fileNumber}
        isDanger
      />
      <InfoDialog
        isOpen={isInfoDialogOpen}
        onCancelClick={() => setIsInfoDialogOpen(false)}
      />
    </>
  );
};

const CloneProject: React.FC<{ project: IProject }> = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [createProject, { isLoading, isError, isSuccess, error }] =
    useCreateProjectMutation();

  const { checkProject, isCheckInProgress, validationError, reset } =
    useCheckProject();

  useEffect(() => {
    if (isError) {
      toast.error(`Operation failed \n\n ${JSON.stringify(error)}`);
    }
    if (isSuccess) {
      toast.success('Operation success');
    }
  }, [isSuccess, isError, error]);
  return (
    <>
      <ContainerFlex direction="row">
        <FiCopy
          title="Clone Project"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsOpen(true)}
        />
      </ContainerFlex>
      <CloneDialog
        isOpen={isOpen}
        onCancelClick={() => {
          reset();
          setIsOpen(false);
        }}
        onConfirmClick={async (fileNumber, crn) => {
          const result = await checkProject(fileNumber);
          if (!result) {
            return;
          }

          await createProject({
            ...project,
            userId: undefined,
            fileNumber,
            crn,
          });
          setIsOpen(false);
        }}
        isLoading={isLoading || isCheckInProgress}
        validationError={validationError}
        originalFileNumber={project.fileNumber}
      />
    </>
  );
};

const ViewProjects = () => {
  const { data, error, isLoading, refetch, isFetching } = useGetProjectsQuery();

  useGetUsersQuery(); // TODO: Maybe its best to move all data fetching to a post-login event

  useCognitoAuth(refetch);

  if (error) {
    console.log(error);
    return <h1>Error fetching projects.</h1>;
  }

  return (
    <>
      <BreadCrumb
        crumbs={[
          { name: 'Home', path: '/admin' },
          { name: 'View Projects', isCurrent: true },
        ]}
      />
      {isLoading || isFetching ? (
        <Spinner
          text={isLoading ? 'Fetching projects' : 'Re-fetching projects'}
        />
      ) : (
        data && <ProjectTable data={data.Items} />
      )}
    </>
  );
};

const ProjectTable: React.FC<{ data: IProject[] }> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      {/* <Spinner /> */}
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default ViewProjects;
