import '@aws-amplify/ui-react/styles.css';

import {
  Authenticator,
  Theme,
  ThemeProvider,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import tw, { styled } from 'twin.macro';

import logo from '../assets/ags-nirman@500.png';
import { Spinner } from '../components';
import { Role } from '../enums/role';
import { useAppDispatch } from '../hooks/redux';
import { userAuthenticated } from '../store/authSlice';

type LocationProps = {
  state: {
    from: Location;
  };
};

const Container = styled.div`
  ${tw`
    flex
    flex-col
    justify-center
    items-center
  `}
`;

const Logo = styled.img`
  ${tw`
    w-48
    md:w-64
  `}
`;

const AuthContainer = styled.div`
  ${tw`
    flex
    justify-center
    items-center
  `}
`;

const StyledP = styled.p`
  margin: 0;
  padding: 2rem 2rem 0 2rem;
  ${tw`
    text-2xl
    text-gray-800
  `}
`;

const theme: Theme = {
  name: 'login',
  tokens: {
    colors: {
      brand: {
        primary: {
          '10': { value: '#ffdada' },
          '80': { value: '#620000' },
          '90': { value: '#620000' },
          '100': { value: '#620000' },
        },
      },
    },
  },
};

const components = {
  SignIn: {
    Header() {
      return <StyledP>Sign in to your account</StyledP>;
    },
  },
};

export function Login() {
  const dispatch = useAppDispatch();

  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);
  const location = useLocation() as unknown as LocationProps;

  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';
  useEffect(() => {
    // if (import.meta.env.VITE_ENVIRONMENT === 'prod') {
    //   navigate('/coming-soon', { replace: true });
    // }

    if (route === 'authenticated') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const role = user.getSignInUserSession()?.getIdToken().decodePayload()[
        'cognito:groups'
      ][0] as Role;

      const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

      dispatch(
        userAuthenticated({
          isAuthenticated: true,
          role,
          userName: user.username!,
          token,
        })
      );

      if (role === Role.Admin) {
        navigate('/admin', { replace: true });
      } else {
        navigate('/user', { replace: true });
      }
    }
  }, [route, user, navigate, from, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Logo src={logo} alt="agsnirmaan logo" />
        <AuthContainer>
          {route === 'signOut' ? (
            <Spinner text="Signing out..." />
          ) : (
            <Authenticator components={components} hideSignUp={true} />
          )}
        </AuthContainer>
      </Container>
    </ThemeProvider>
  );
}
