/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiLogOut, FiUser } from 'react-icons/fi';
import tw, { css, styled } from 'twin.macro';

import { useAppDispatch } from '../../hooks';
import { adminApi, adminReset, authReset } from '../../store';

const MenuContainer = tw.div`
  relative
`;

const MenuButton = styled.button`
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  :hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
  span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
  }
  img {
    border-radius: 90px;
  }
  ${tw`
    p-1.5
    md:p-2
  `}
`;

const Menu = styled.nav<{ isActive: boolean }>`
  background: #ffffff;
  ${tw`
      rounded-lg
      overflow-hidden
  `}
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
  z-index: 10;
  ${(props) =>
    props.isActive
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        `
      : css`
          visibility: hidden;
        `}
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li:last-child {
      border: none;
    }
    li {
      border-bottom: 1px solid #dddddd;
      padding: 0.75rem;
      ${tw`
        m-1
        hover:bg-[rgb(249, 250, 251)]
      `}
      a {
        text-decoration: none;
        color: #333333;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
`;

const MenuButtonContainer = tw.div`
  flex 
  items-center 
  gap-1 
  cursor-pointer
`;

const Username = tw.p`
  font-semibold
  truncate
`;

const SignoutButton = tw.button`
  flex
  gap-2
  items-center
  w-full
`;

export const SettingsMenu = () => {
  const dispatch = useAppDispatch();
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => {
    setIsActive(!isActive);
  };

  const onSignOut = () => {
    dispatch(authReset());
    dispatch(adminReset());
    dispatch(adminApi.util.resetApiState());

    // window.$chatwoot.toggleBubbleVisibility('hide');

    signOut();
  };

  useEffect(() => {
    const pageClickEvent = (e: any) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  return (
    <MenuContainer ref={dropdownRef}>
      <MenuButtonContainer onClick={onClick}>
        <MenuButton>
          <FiUser size={'1.5rem'} />
        </MenuButton>
        <FiChevronDown />
      </MenuButtonContainer>
      <Menu isActive={isActive}>
        <ul>
          <li>
            <p>Signed in as</p>
            <Username>{user.username}</Username>
          </li>
          <li>
            <SignoutButton onClick={onSignOut}>
              Signout <FiLogOut />
            </SignoutButton>
          </li>
        </ul>
      </Menu>
    </MenuContainer>
  );
};
