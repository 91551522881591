/* eslint-disable react/no-unknown-property */
import { IProject } from '@agsnirman/core/types';
import dayjs from 'dayjs';
import React from 'react';
import { FiThumbsUp } from 'react-icons/fi';
import tw from 'twin.macro';

import { Circle, Inner } from '../../styles';
import { currencyFormat } from '../../utils/currencyFormat';

const Grid = tw.div`
  grid
  md:grid-cols-2
  gap-4
  md:gap-8
  md:p-8
  md:max-w-4xl
  mx-auto
  relative
`;

const Card = tw.div`
  max-w-md
  md:max-w-md
  rounded
  border
  border-solid
  border-gray-800
  shadow-md 
  flex
  flex-col
  gap-3
  bg-white
  hover:bg-[rgb(249, 250, 251)]
`;

const CardTitle = tw.h5`
  text-xl 
  font-semibold 
  tracking-tight 
  self-center
  w-full
  rounded-t-xl
  text-center
  p-4
  text-rosewood-700
`;

const CardBody = tw.div`
  flex 
  flex-col 
  gap-4
  p-4
  md:p-6
`;

export const ProjectSnapshot: React.FC<{ project?: IProject }> = ({
  project,
}) => {
  if (!project) {
    return <></>;
  }

  let totalPaymentReceivedPercent = '0';
  if (
    project.projectStatus?.totalPaymentReceived &&
    project.totalEstimatedCost
  ) {
    totalPaymentReceivedPercent = parseFloat(
      (
        (project.projectStatus?.totalPaymentReceived /
          project.totalEstimatedCost) *
        100
      ).toString()
    ).toFixed(2);
  }
  return (
    <Grid>
      <Card>
        <CardTitle>Project details</CardTitle>
        <CardBody>
          <p tw="flex gap-4 justify-between items-center">
            <span tw="border-0 border-solid border-b-2">File Number:</span>
            <span tw="text-sm font-semibold">{project.fileNumber}</span>
          </p>
          <p tw="flex gap-4 justify-between items-center">
            <span tw="border-0 border-solid border-b-2">CRN:</span>
            <span tw="text-sm font-semibold">{project.crn}</span>
          </p>
          <p tw="flex gap-4 justify-between items-center">
            <span tw="border-0 border-solid border-b-2">
              Total Estimated Cost:
            </span>
            <span tw="text-sm font-semibold">
              {currencyFormat(project.totalEstimatedCost)}
            </span>
          </p>
          <p tw="flex gap-4 justify-between items-center">
            <span tw="border-0 border-solid border-b-2">Last Updated:</span>
            <span tw="text-sm font-semibold">
              {dayjs(project.updatedOn).format('DD MMM YYYY hh:mm A')}
            </span>
          </p>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>Total balance overdue</CardTitle>
        <CardBody tw="self-center items-center">
          {project.projectStatus?.balanceStatus &&
          project.projectStatus?.balanceStatus.totalBalanceOverdue &&
          project.projectStatus?.balanceStatus.totalBalanceOverdue > 0 ? (
            <>
              <div tw="flex gap-4">
                <p> {project.projectStatus?.balanceStatus.phase} :</p>
                <p tw="text-red-500">
                  -
                  {currencyFormat(
                    project.projectStatus?.balanceStatus?.totalBalanceOverdue
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              <p>Payment up to date</p>
              <FiThumbsUp size={'1.5rem'} color="#12784f" />
            </>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardTitle>Total project progress</CardTitle>
        <CardBody tw="self-center items-center">
          <Circle progress={project.projectStatus?.totalProjectProgress || 0}>
            <Inner>{project?.projectStatus?.totalProjectProgress || 0}%</Inner>
          </Circle>
          {/* <a
            href="abc"
            tw="flex items-center font-medium text-rosewood-light hover:text-rosewood-900"
          >
            View Gantt Chart
            <FiChevronRight />
          </a> */}
        </CardBody>
      </Card>

      <Card>
        <CardTitle>Total payment received</CardTitle>
        <CardBody tw="self-center items-center">
          <Circle progress={parseFloat(totalPaymentReceivedPercent)}>
            <Inner>{totalPaymentReceivedPercent}%</Inner>
          </Circle>
          <p tw="text-[#12784f]">
            {currencyFormat(project.projectStatus?.totalPaymentReceived)}
          </p>
        </CardBody>
      </Card>
    </Grid>
  );
};
