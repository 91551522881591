import {
  BuildingModel,
  IPlotSize,
  IProject,
  ISize,
  IStructural,
  Permissions,
  ProjectType,
} from '@agsnirman/core/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { FormMode } from '~/enums/formMode';
import { IProjectForm } from '~/models/projectForm';

export interface AdminState {
  projectForm: IProjectForm;
  error: boolean;
}

export const projectFormDefault: IProjectForm = {
  mode: FormMode.CREATE,
  status: 'reset',
  project: {
    crn: '',
    fileNumber: '',
    clientName: '',
    projectType: ProjectType.Residential,
    permissions: Permissions.Self,
    buildingModel: BuildingModel.Duplex,
    structural: {} as IStructural,
    plotSize: {} as IPlotSize,
    slabSize: {} as IPlotSize,
    facing: '',
    roadSize: {} as ISize,
    setBacks: {} as ISize,
    projectStarted: false,
  },
};

const initialState: AdminState = {
  projectForm: projectFormDefault,
  error: false,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    projectFormInitialised: (state, action: PayloadAction<IProjectForm>) => {
      state.projectForm = action.payload;
    },
    projectFormUpdated: (state, action: PayloadAction<IProject>) => {
      state.projectForm.project = {
        ...state.projectForm.project,
        ...action.payload,
      };
    },
    projectFormCleared: (state) => {
      state.projectForm = projectFormDefault;
    },
    setError: (state) => {
      state.error = true;
    },
    adminReset: () => initialState,
  },
});

export const {
  projectFormInitialised,
  projectFormUpdated,
  projectFormCleared,
  setError,
  adminReset,
} = adminSlice.actions;

const api = axios.create({
  baseURL: import.meta.env.VITE_FILES_API_URL,
  headers: {
    Accept: 'text/plain',
  },
});

export function getSignedCookie(authToken: string) {
  // eslint-disable-next-line @typescript-eslint/require-await
  return async (dispatch: any) => {
    api.defaults.headers.get['Authorization'] = authToken;
    api.defaults.withCredentials = true;

    api
      .get('/signed-cookie')
      .then((response) => {
        // dispatch(projectsFetched(response.data));
        console.log(response);
      })
      .catch(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        dispatch(setError());
      });
  };
}

export default adminSlice.reducer;
