import { Link } from 'react-router-dom';
import tw, { css, styled, TwStyle } from 'twin.macro';

export const ContainerGrid = styled.div`
  ${tw`
    grid
    md:grid-cols-2
    gap-4
    md:gap-8
    p-8
    max-w-3xl
    mx-auto
    relative
`}
`;

export const ContainerFlex = styled.div<{ direction?: 'row' | 'col' }>`
  ${tw`
    flex
    justify-center
    items-center
    gap-2
  `}
  ${(props) =>
    props.direction === 'row'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}
`;

type SizeVariant = 'small' | 'medium' | 'large';

interface IButtonProps {
  sizeVariant?: SizeVariant;
}

const buttonSizeVariants: Record<SizeVariant, TwStyle> = {
  small: tw`w-8`,
  medium: tw`w-12`,
  large: tw`w-24`,
};

export const Button1 = styled.button<IButtonProps>(() => [
  tw`
    flex
    items-center
    gap-2
    flex-shrink-0
    text-white
    bg-rosewood-900 
    border-0 
    py-2 
    px-4 
    focus:outline-none 
    hover:bg-rosewood-light 
    rounded  
    cursor-pointer
    self-end
    my-4
    text-sm
    md:text-base
`,
  ({ sizeVariant = 'large' }) => buttonSizeVariants[sizeVariant],
]);

export const Button = styled.button`
  ${tw`
    flex
    items-center
    gap-2
    flex-shrink-0
    text-white
    bg-rosewood-light 
    border-0 
    py-2 
    px-4 
    focus:outline-none 
    hover:bg-rosewood-900 
    rounded  
    cursor-pointer
    my-4
    text-sm
    md:text-base
    disabled:bg-rosewood-light
    disabled:cursor-not-allowed
`}
`;

export const Card = styled.div`
  ${tw`
    max-w-sm 
    rounded
    overflow-hidden 
    shadow-lg
    bg-white
    hover:bg-[rgb(249, 250, 251)]
    cursor-pointer
    border
    border-solid
    border-gray-800
    p-4
`}
`;

export const StyledLink = styled(Link)`
  ${tw`
    text-lg
    no-underline
    p-2
    transition-colors 
    duration-200 
    ease-in-out
    flex
    items-center
    gap-1
`}
`;

export const H1 = tw.h1`
  text-2xl
  font-normal
  leading-normal
  mt-0
  mb-2
`;

export const FormContainer = styled.div<{
  isFullWidth?: boolean;
}>(() => [
  tw`
  border-2
  border-solid
  border-rosewood-900
  rounded
  py-12
  px-4
  md:px-16
  shadow-2xl
  xl:w-[50%]
  mx-auto
`,
  ({ isFullWidth }) => isFullWidth && tw`md:w-full`,
]);

export const FormLabel = styled.label`
  ${tw`
    leading-7 
    text-sm
    md:text-base
    text-gray-600
  `}
`;

export const FormInput = styled.input<{ isError?: boolean }>`
  ${tw`
    w-full
    rounded 
    border
    border-solid
    border-gray-300 
    bg-gray-50
    focus:bg-white
    focus:ring-2
    focus:ring-rosewood-900
    text-base 
    outline-none
    text-gray-700 
    py-1 
    px-3 
    leading-8 
    transition-colors 
    duration-200 
    ease-in-out
    disabled:bg-gray-200
  `}
  ${(props) =>
    props.isError &&
    css`
      border: 1px solid red;
    `}
`;

export const FormField = styled.div`
  ${tw`
  relative 
  mb-4
`}
`;

export const ActionButton = styled(Button)`
  ${tw`
    w-full
    justify-center
    ml-auto
    disabled:bg-rosewood-light
    disabled:cursor-not-allowed
`}
`;

export const StyledH1 = tw(H1)`
  flex
  justify-center
  items-center
`;

export const TableContainer = tw.div`
  h-auto
  overflow-auto
  rounded-xl
`;

export const Table = styled.table`
  ${tw`
    border
    border-solid
    border-[#ededed]
    w-full
    table-fixed
    text-sm
    md:text-base
  `}
  border-spacing: 0;
  tr:last-child td {
    border-bottom: 0;
  }
  th {
    ${tw`
      bg-rosewood-900
      text-white
      font-normal
      p-1
      border
      border-solid
      border-gray-400
      border-opacity-30
    `}
  }
  td {
    ${tw`
      m-0
      p-2
      border
      border-solid
      border-b-[#ededed]
      relative
      text-center
    `}
  }
  th:last-child,
  td:last-child {
    border-right: 0;
  }
  tr:nth-child(even) {
    background-color: #fafafa;
  }
`;

export const Circle = styled.div<{ progress: number }>`
  position: relative;
  width: 5rem;
  height: 5rem;
  margin: 0.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-image: ${(props) =>
    `conic-gradient(#12784f ${props.progress}%, #dce3e6 0)`};
`;

export const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  background: #fff;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 700;
  color: #12784f;
`;

export const StatusText = tw.p`
    font-bold
    whitespace-nowrap
`;

export const Error = tw.p`
  text-red-600
  text-xs
  md:text-sm
  mt-1
`;
