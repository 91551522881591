/* eslint-disable react/no-unknown-property */
import { IProject } from '@agsnirman/core/types';
import React from 'react';
import tw, { css, styled } from 'twin.macro';

const borderStyle = css`
  ${tw`
    border
    border-solid
    border-gray-300
    border-collapse
  `}
`;

const Table = styled.table`
  ${tw`
    text-xs
    md:text-sm
    w-full
  `}
  ${borderStyle}
  td {
    ${borderStyle}
    ${tw`
      px-2
      py-2
    `}
  }
  td:nth-child(1) {
    ${tw`
      font-bold
    `}
  }
  td:nth-child(2) {
    ${tw`
      bg-gray-50
    `}
  }
`;

const GridView = tw.div`
  grid
  grid-cols-1
  md:grid-cols-2
  gap-2
  border
  border-dashed
  border-gray-300
  p-1
`;

const GridViewItem = styled.div`
  ${tw`
    flex
    items-center
    justify-between
    mr-4
  `}
  span:nth-child(2) {
    font-weight: bold;
  }
`;

interface IProjectSummaryProps {
  project?: IProject;
}

export const ProjectSummary: React.FC<IProjectSummaryProps> = ({ project }) => {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return (
    <Table>
      <tbody>
        <tr>
          <td>FILE NUMBER</td>
          <td>{project?.fileNumber}</td>
        </tr>
        <tr>
          <td>CRN</td>
          <td>{project?.crn}</td>
        </tr>
        <tr>
          <td>CLIENT NAME</td>
          <td>{project?.clientName}</td>
        </tr>
        <tr>
          <td>LOCATION</td>
          <td>{project?.location}</td>
        </tr>
        <tr>
          <td>
            <span tw="bg-yellow-500 p-1">TOTAL ESTIMATED COST</span>
          </td>
          <td>
            <span tw="bg-yellow-500 p-1">
              {' '}
              {project &&
                project.totalEstimatedCost &&
                formatter.format(project.totalEstimatedCost)}
            </span>
          </td>
        </tr>
        <tr>
          <td>PROJECT TYPE</td>
          <td>{project?.projectType}</td>
        </tr>
        <tr>
          <td>PERMISSIONS</td>
          <td>{project?.permissions}</td>
        </tr>
        <tr>
          <td>BUILDING MODEL</td>
          <td>{project?.buildingModel}</td>
        </tr>
        <tr>
          <td>FLOORS</td>
          <td>{project?.floors}</td>
        </tr>
        <tr>
          <td>PROVISIONS</td>
          <td>{project?.provisions}</td>
        </tr>
        <tr>
          <td>STRUCTURAL</td>
          <td>
            <GridView>
              <GridViewItem>
                <span>FLOORS</span>
                <span>{project?.structural?.floors}</span>
              </GridViewItem>
              <GridViewItem>
                <span>LOAD</span>
                <span>{project?.structural?.load}</span>
              </GridViewItem>
              <GridViewItem>
                <span>TYPE</span>
                <span>{project?.structural?.type}</span>
              </GridViewItem>
              <GridViewItem>
                <span>ELEVATION</span>
                <span>{project?.structural?.elevation}</span>
              </GridViewItem>
            </GridView>
          </td>
        </tr>
        <tr>
          <td>PLOT SIZE</td>
          <td>
            <GridView>
              <GridViewItem>
                <span>LENGTH</span>
                <span>{project?.plotSize?.length}</span>
              </GridViewItem>
              <GridViewItem>
                <span>WIDTH</span>
                <span>{project?.plotSize?.width}</span>
              </GridViewItem>
              <GridViewItem>
                <span>TOTAL</span>
                <span>
                  {project?.plotSize?.length &&
                    project?.plotSize?.width &&
                    project?.plotSize?.length * project?.plotSize?.width}
                </span>
              </GridViewItem>
              <GridViewItem>
                <span>SQ. YARDS</span>
                <span></span>
              </GridViewItem>
            </GridView>
          </td>
        </tr>
        <tr>
          <td>FACING</td>
          <td>{project?.facing}</td>
        </tr>
        <tr>
          <td>ROAD SIZE</td>
          <td>
            <GridView>
              <GridViewItem>
                <span>EAST</span>
                <span>{project?.roadSize?.east}</span>
              </GridViewItem>
              <GridViewItem>
                <span>WEST</span>
                <span>{project?.roadSize?.west}</span>
              </GridViewItem>
              <GridViewItem>
                <span>NORTH</span>
                <span>{project?.roadSize?.north}</span>
              </GridViewItem>
              <GridViewItem>
                <span>SOUTH</span>
                <span>{project?.roadSize?.south}</span>
              </GridViewItem>
            </GridView>
          </td>
        </tr>
        <tr>
          <td>SET BACKS</td>
          <td>
            <GridView>
              <GridViewItem>
                <span>EAST</span>
                <span>{project?.setBacks?.east}</span>
              </GridViewItem>
              <GridViewItem>
                <span>WEST</span>
                <span>{project?.setBacks?.west}</span>
              </GridViewItem>
              <GridViewItem>
                <span>NORTH</span>
                <span>{project?.setBacks?.north}</span>
              </GridViewItem>
              <GridViewItem>
                <span>SOUTH</span>
                <span>{project?.setBacks?.south}</span>
              </GridViewItem>
            </GridView>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
