import { useState } from 'react';

import { useLazyCheckProjectQuery } from '../store';

export function useCheckProject() {
  const [validationError, setValidationError] = useState(false);
  const [isCheckInProgress, setIsCheckInProgress] = useState(false);

  const [trigger] = useLazyCheckProjectQuery();

  const checkProject = async (fileNumber: string) => {
    try {
      setValidationError(false);
      setIsCheckInProgress(true);

      const checkResult = await trigger(fileNumber);
      if (checkResult.isSuccess && checkResult.data) {
        setValidationError(true);
        return false;
      }
    } finally {
      setIsCheckInProgress(false);
    }

    return true;
  };

  const reset = () => {
    setValidationError(false);
  };

  return { validationError, isCheckInProgress, checkProject, reset };
}
