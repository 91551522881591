import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle({
  body: {
    // WebkitTapHighlightColor: theme`colors.purple.500`,
    ...tw`antialiased`,
  },
});

const HtmlStyle = createGlobalStyle({
  html: {
    overflowY: 'scroll',
    backgroundColor: 'rgb(249, 250, 251)',
  },
});

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <HtmlStyle />
    <CustomStyles />
  </>
);

export default GlobalStyles;
