'use client';

import './main.css';

import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import App from './app';
import { ErrorFallback } from './components';
import { sentry } from './config';
import { store } from './store';
import GlobalStyles from './styles';

sentry.configure();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <GlobalStyles />
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={sentry.logError}>
      <App />
    </ErrorBoundary>
  </Provider>
);
