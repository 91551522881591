import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // if (import.meta.env.VITE_ENVIRONMENT === 'prod') {
    //   navigate('/coming-soon', { replace: true });
    // } else {
    //   navigate('/login', { replace: true });
    // }
    navigate('/login', { replace: true });
  }, [navigate]);

  return <></>;
};
