import { useParams } from 'react-router-dom';

import { ProjectHome } from '../../components';
import { useGetProjectsQuery } from '../../store';

export const AdminProjectHome = () => {
  const { fileNumber } = useParams();

  const { data } = useGetProjectsQuery();
  const project = data?.Items.find((p) => p.fileNumber === fileNumber);
  return (
    <ProjectHome
      crumbs={[
        { name: 'Home', path: '/admin' },
        { name: 'View Projects', path: '/admin/projects' },
        { name: fileNumber!, isCurrent: true },
      ]}
      project={project}
    />
  );
};
