import { Amplify } from '@aws-amplify/core';

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolWebClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    },
  });
}
