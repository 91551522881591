import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Role } from '../enums/role';
import { Layout } from '../layouts/main';
import { AdminHeader } from '../pages/admin/components/header/header';
import { AdminDashboard } from '../pages/admin/dashboard';
import { ManageUsers } from '../pages/admin/manage-users';
import ProjectForm from '../pages/admin/project-form';
import { AdminProjectHome } from '../pages/admin/project-home';
import { ProjectQuickView } from '../pages/admin/project-quick-view';
import { ProjectUserLink } from '../pages/admin/project-user-link';
import ViewProjects from '../pages/admin/view-projects';
import { Home } from '../pages/home';
import { Login } from '../pages/login';
import { UserHeader } from '../pages/user/components/header/header';
import { UserDashboard } from '../pages/user/dashboard';
import { UserProjectHome } from '../pages/user/project-home';
import { ProtectedRoute } from './protected-route';

const ProjectStatusPage = lazy(() => import('../pages/admin/project-status'));

export const RoutesSetup = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="login" element={<Layout />}>
        <Route index element={<Login />} />
      </Route>
      {/* <Route path="coming-soon" element={<ComingSoon />} /> */}
      <Route element={<ProtectedRoute role={Role.Admin} />}>
        <Route path="admin" element={<Layout header={<AdminHeader />} />}>
          <Route index element={<AdminDashboard />} />
          <Route path="projects" element={<ViewProjects />} />
          <Route
            path="project/:fileNumber/view"
            element={<ProjectQuickView />}
          />
          <Route path="project/:fileNumber/edit" element={<ProjectForm />} />
          <Route
            path="project/:fileNumber/status"
            element={
              <Suspense fallback={<>Loading...</>}>
                <ProjectStatusPage />
              </Suspense>
            }
          />
          <Route
            path="project/:fileNumber/link"
            element={<ProjectUserLink />}
          />
          <Route path="project/:fileNumber" element={<AdminProjectHome />} />
          <Route path="create-project" element={<ProjectForm />} />
          <Route path="users" element={<ManageUsers />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute role={Role.User} />}>
        <Route path="user" element={<Layout header={<UserHeader />} />}>
          <Route index element={<UserDashboard />} />
          <Route path="project/:fileNumber" element={<UserProjectHome />} />
        </Route>
      </Route>
    </Routes>
  );
};
