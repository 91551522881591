import React from 'react';
import { Outlet } from 'react-router-dom';
import tw from 'twin.macro';

import { Footer } from './footer';

export interface ILayoutProps {
  header?: React.ReactElement;
}

const Main = tw.main`
  py-8
  flex-1
`;

export const Layout: React.FC<ILayoutProps> = ({ header }) => {
  return (
    <>
      {header}
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};
