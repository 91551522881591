/* eslint-disable react/no-unknown-property */
import { FiDownload } from 'react-icons/fi';
import { MdPictureAsPdf } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { useGetUserReportsQuery } from '../../store/apiSlice';
import { getFormattedSize } from '../../utils/fileSizeFormat';
import { Spinner } from '../common';

const AnchorLink = tw.a`
  underline
  text-blue-600
  hover:text-blue-800
  visited:text-purple-600
`;

export const ProjectDocuments = () => {
  const params = useParams();
  // const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const { data, isLoading } = useGetUserReportsQuery({
    fileNumber: params.fileNumber!,
    type: 'reports',
  });

  // const handleNextPage = () => {
  //   setCurrentPageIndex(currentPageIndex + 1);
  // };

  // const handlePreviousPage = () => {
  //   setCurrentPageIndex(currentPageIndex - 1);
  // };

  // const handleOnChange = (
  //   newPageIndex: number /*, prevPageIndex: number */
  // ) => {
  //   setCurrentPageIndex(newPageIndex);
  // };

  if (isLoading) {
    return <Spinner text="Fetching Reports" />;
  }

  if (!data || !data.Count) {
    return <p>Check back later</p>;
  }

  // const totalPages = Math.ceil(data?.Count / 10);

  return (
    <div tw="relative overflow-x-auto">
      <table tw="w-96 text-sm text-left text-gray-500">
        <thead tw="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th></th>
            <th scope="col" tw="px-6 py-3">
              Name
            </th>
            <th scope="col" tw="px-6 py-3">
              Size
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.Items?.map((i) => (
            <tr key={i.name} tw="bg-white border-b">
              <td>
                <MdPictureAsPdf size={'2rem'} color="#FA0F00" />
              </td>
              <td tw="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {i.name}
              </td>
              <td tw="px-6 py-4">{getFormattedSize(i.size)}</td>
              <td>
                <AnchorLink
                  href={`https://${import.meta.env.VITE_FILES_DOMAIN_NAME}/${
                    i.path
                  }`}
                  target="_blank"
                >
                  <FiDownload size={'1.25rem'} />
                </AnchorLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
