import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';

import { userAuthenticated } from '../store';
import { useAppDispatch, useAppSelector } from './redux';

interface Session {
  getIdToken: () => { getJwtToken: () => string };
}

export function useCognitoAuth(cb: () => void) {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator((context) => [context.user]);

  const loggedInUser = useAppSelector((state) => state.auth.user);
  const isSessionValid = user.getSignInUserSession()?.isValid();

  const refreshSession = () => {
    const refreshToken = user.getSignInUserSession()?.getRefreshToken();
    if (refreshToken) {
      user.refreshSession(refreshToken, (_, session: Session) => {
        dispatch(
          userAuthenticated({
            ...loggedInUser,
            token: session.getIdToken().getJwtToken(),
          })
        );
        cb();
      });
    }
  };

  useEffect(() => {
    if (!isSessionValid) {
      console.log(`Session is invalid. Refreshing now.`);
      refreshSession();
    }
  });
}
