import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect } from 'react';
import { BiBuildingHouse } from 'react-icons/bi';
import { FiChevronRight, FiEdit2, FiUsers } from 'react-icons/fi';

import { useAppDispatch } from '../../hooks';
import { getSignedCookie } from '../../store';
import {
  Card,
  ContainerFlex,
  ContainerGrid,
  H1,
  StyledLink,
} from '../../styles';

export const AdminDashboard = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    // TODO: Only trigger if the cookie isn't already set
    void (async () => {
      const session = await Auth.currentSession();
      if (session.isValid()) {
        await dispatch(getSignedCookie(session.getIdToken().getJwtToken()));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <H1>Welcome {user.username}</H1>
      <ContainerGrid>
        <Card>
          <ContainerFlex direction="row">
            <StyledLink to="projects">
              View Projects <BiBuildingHouse />
            </StyledLink>
            <FiChevronRight />
          </ContainerFlex>
        </Card>
        <Card>
          <ContainerFlex direction="row">
            <StyledLink to="create-project">
              Create Project <FiEdit2 />
            </StyledLink>
            <FiChevronRight />
          </ContainerFlex>
        </Card>
        <Card>
          <ContainerFlex direction="row">
            <StyledLink to="users">
              Manage Users <FiUsers />
            </StyledLink>
            <FiChevronRight />
          </ContainerFlex>
        </Card>
      </ContainerGrid>
    </>
  );
};
