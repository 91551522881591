import { useNavigate, useParams } from 'react-router-dom';

import { useGetProjectsQuery } from '~/store/apiSlice';

import { BreadCrumb } from '../../components';
import { ActionButton, FormContainer, StyledH1 } from '../../styles';
import { ProjectSummary } from './components';

export const ProjectQuickView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data } = useGetProjectsQuery();

  const currentProject = data?.Items.find(
    (p) => p.fileNumber === params.fileNumber
  );

  return (
    <>
      <BreadCrumb
        crumbs={[
          { name: 'Home', path: '/admin' },
          { name: 'View Projects', path: '/admin/projects' },
          { name: 'Summary', isCurrent: true },
        ]}
      />
      <FormContainer>
        <StyledH1>{currentProject?.fileNumber}</StyledH1>
        <ProjectSummary project={currentProject} />
        <ActionButton
          onClick={() =>
            currentProject?.fileNumber &&
            navigate(`/admin/project/${currentProject?.fileNumber}/edit`)
          }
        >
          Edit Project
        </ActionButton>
      </FormContainer>
    </>
  );
};
