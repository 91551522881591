import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

export interface ICrumb {
  name: string;
  path?: string;
  isCurrent?: boolean;
}

interface IBreadCrumbProps {
  crumbs: ICrumb[];
}

const BreadCrumbContainer = styled.nav`
  ${tw`
    mb-4
    flex
    items-center
    gap-2
  `}
`;

const CrumbLink = styled(Link)`
  ${tw`
    flex
    items-center
    gap-1
    text-rosewood-light
  `}
`;

const LinkContainer = tw.span`
  flex
  items-center
  gap-1
`;

export const BreadCrumb: React.FC<IBreadCrumbProps> = ({ crumbs }) => {
  return (
    <BreadCrumbContainer>
      {crumbs.map((c) => {
        return c.path ? (
          <LinkContainer key={c.name}>
            <CrumbLink to={c.path}>{c.name} </CrumbLink>
            <FiChevronRight />
          </LinkContainer>
        ) : (
          <span key={c.name}>{c.name}</span>
        );
      })}
    </BreadCrumbContainer>
  );
};
