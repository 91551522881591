import { Authenticator } from '@aws-amplify/ui-react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import tw from 'twin.macro';

import { configureAmplify } from './config';
import { RoutesSetup } from './routes';

configureAmplify();

const Container = tw.div`
  container
  px-4
  md:px-8
  py-4
  font-Inter
  flex
  flex-col
  h-screen
  text-sm
  md:text-base
`;

function App() {
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <Container>
          <Toaster />
          <RoutesSetup />
        </Container>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}

export default App;
