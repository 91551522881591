import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, Outlet } from 'react-router-dom';

import { Role } from '../enums/role';
import { IProps } from '../models/props';

export interface IProtectedRouteProps extends IProps {
  role: Role;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  children,
  role,
}) => {
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  if (route !== 'authenticated') {
    return <Navigate to="/" replace />;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const currentRole = user.getSignInUserSession()?.getIdToken().decodePayload()[
    'cognito:groups'
  ][0] as Role;

  if (currentRole !== role) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />;
};
