/* eslint-disable react-hooks/exhaustive-deps */
import { FILE_NUMBER_FORMAT } from '@agsnirman/core/constants';
import {
  BuildingModel,
  IProject,
  Permissions,
  ProjectType,
} from '@agsnirman/core/types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FiArrowLeft, FiLock } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import Select, { Colors, Theme } from 'react-select';
import tw, { styled } from 'twin.macro';

import { BreadCrumb, Spinner } from '../../components';
import {
  buildingModelSelectOptions,
  permissionsSelectOptions,
  projectTypeSelectOptions,
} from '../../constants/selectOptions';
import { FormMode } from '../../enums/formMode';
import { useAppDispatch, useAppSelector, useCheckProject } from '../../hooks';
import {
  projectFormCleared,
  projectFormDefault,
  projectFormInitialised,
  projectFormUpdated,
  useCreateProjectMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from '../../store';
import {
  ActionButton,
  Error,
  FormContainer,
  FormField,
  FormInput,
  FormLabel,
  StyledH1,
} from '../../styles';
import { ConfirmDialog, ProjectSummary } from './components';

const FormInputContainer = tw.div`
  flex
  items-center
  gap-1
`;

const H2 = styled.h2`
  ${tw`
  text-gray-900 
  text-lg 
  mb-4
  font-medium
  flex
  items-center
  gap-2
`}
`;

const BackButton = styled(FiArrowLeft)`
  ${tw`
    cursor-pointer
  `}
`;

const FormGroup = styled.div`
  ${tw`
    grid
    grid-cols-1
    md:grid-cols-2
    gap-2
    border
    border-dashed
    border-gray-300
    p-1
  `}
  ${FormLabel},
  ${FormInput} {
    ${tw`
      text-xs
      md:text-sm
    `}
  }
  ${FormInput} {
    ${tw`
      w-28
    `}
  }
`;

const FormGroupItem = tw.div`
  flex
  items-center
  justify-between
  gap-4
`;

interface IStepProps {
  onNext?: () => void;
}

const Step1: React.FC<IStepProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const projectForm = useAppSelector((state) => state.admin.projectForm);
  const { checkProject, isCheckInProgress, validationError } =
    useCheckProject();

  const project = projectForm.project;

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm<IProject>(); //{ mode: 'all' }

  const onSubmit = async (values: IProject) => {
    if (projectForm.mode === FormMode.CREATE) {
      const result = await checkProject(values.fileNumber);
      if (!result) {
        return;
      }
    }

    dispatch(projectFormUpdated(values));
    onNext && onNext();
  };

  useEffect(() => {
    project?.crn && setValue('crn', project?.crn);
    project?.fileNumber && setValue('fileNumber', project?.fileNumber);
    project?.clientName && setValue('clientName', project?.clientName);
    project?.location && setValue('location', project?.location);
    project?.totalEstimatedCost &&
      setValue('totalEstimatedCost', project?.totalEstimatedCost);
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel htmlFor="crn">CRN</FormLabel>
          <FormInputContainer>
            <FormInput
              type="text"
              id="crn"
              {...register('crn', {
                required: true,
              })}
              defaultValue={project?.crn}
              disabled={projectForm.mode === FormMode.EDIT}
            />
            {projectForm.mode === FormMode.EDIT && <FiLock size={'1.25rem'} />}
          </FormInputContainer>

          {errors.crn && <Error>CRN is required</Error>}
        </FormField>
        <FormField>
          <FormLabel htmlFor="fileNumber">File Number</FormLabel>
          <FormInputContainer>
            <FormInput
              type="text"
              id="fileNumber"
              {...register('fileNumber', {
                required: {
                  value: true,
                  message: 'File Number is required',
                },
                pattern: {
                  value: FILE_NUMBER_FORMAT,
                  message: 'Invalid format',
                },
              })}
              defaultValue={project?.fileNumber}
              disabled={projectForm.mode === FormMode.EDIT}
            />
            {projectForm.mode === FormMode.EDIT && <FiLock size={'1.25rem'} />}
          </FormInputContainer>
          {errors.fileNumber && <Error>{errors.fileNumber.message}</Error>}
          {validationError && (
            <Error>Another project with the same file number exists</Error>
          )}
        </FormField>
        <FormField>
          <FormLabel htmlFor="clientName">Client Name</FormLabel>
          <FormInput
            type="text"
            id="clientName"
            {...register('clientName', {
              required: true,
            })}
            defaultValue={project?.clientName}
          />
          {errors.clientName && <Error>Client Name is required</Error>}
        </FormField>
        <FormField>
          <FormLabel htmlFor="location">Location</FormLabel>
          <FormInput
            type="text"
            id="location"
            {...register('location', {
              required: true,
            })}
            defaultValue={project?.location}
          />
          {errors.location && <Error>Location is required</Error>}
        </FormField>
        <FormField>
          <FormLabel htmlFor="totalEstimatedCost">
            Total Estimated Cost
          </FormLabel>
          <FormInput
            type="text"
            id="totalEstimatedCost"
            {...register('totalEstimatedCost')}
            defaultValue={project?.totalEstimatedCost}
          />
        </FormField>
        <ActionButton type="submit">
          {isCheckInProgress ? 'Validating File Number ...' : 'Next'}
          {isCheckInProgress && <Spinner />}
        </ActionButton>
      </form>
    </>
  );
};

// TODO: read colors from tailwind config ?
const selectThemeFn = (theme: Theme) =>
  ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#ffdada',
      primary: '#620000',
    } as Colors,
    spacing: {
      baseUnit: 6,
    },
  } as Theme);

const Step2: React.FC<IStepProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const projectForm = useAppSelector((state) => state.admin.projectForm);

  const project = projectForm.project;
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<IProject>(); //{ mode: 'all' }

  const onSubmit = (values: IProject) => {
    dispatch(projectFormUpdated(values));
    onNext && onNext();
  };

  useEffect(() => {
    setValue(
      'buildingModel',
      buildingModelSelectOptions.find((s) => s.value === project?.buildingModel)
        ?.value as BuildingModel
    );
    setValue(
      'projectType',
      projectTypeSelectOptions.find((s) => s.value === project?.projectType)
        ?.value as ProjectType
    );
    setValue(
      'permissions',
      permissionsSelectOptions.find((s) => s.value === project?.permissions)
        ?.value as Permissions
    );
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel htmlFor="projectType">Project Type</FormLabel>
          <Controller
            name="projectType"
            control={control}
            render={({ field: { onChange, name } }) => (
              <Select
                // inputRef={ref}
                defaultValue={projectTypeSelectOptions.find(
                  (s) => s.value === project?.projectType
                )}
                name={name}
                options={projectTypeSelectOptions}
                onChange={(newValue, _) => {
                  onChange(newValue?.value);
                }}
                theme={selectThemeFn}
              />
            )}
          />
        </FormField>
        <FormField>
          <FormLabel htmlFor="permissions">Permissions</FormLabel>
          <Controller
            name="permissions"
            control={control}
            render={({ field: { onChange, name } }) => (
              <Select
                defaultValue={permissionsSelectOptions.find(
                  (s) => s.value === project?.permissions
                )}
                name={name}
                options={permissionsSelectOptions}
                onChange={(newValue, _) => {
                  onChange(newValue?.value);
                }}
                theme={selectThemeFn}
              />
            )}
          />
        </FormField>
        <FormField>
          <FormLabel htmlFor="buildingModel">Building Model</FormLabel>
          <Controller
            name="buildingModel"
            control={control}
            render={({ field: { onChange, name } }) => (
              <Select
                defaultValue={buildingModelSelectOptions.find(
                  (s) => s.value === project?.buildingModel
                )}
                name={name}
                options={buildingModelSelectOptions}
                onChange={(newValue, _) => {
                  onChange(newValue?.value);
                }}
                theme={selectThemeFn}
              />
            )}
          />
        </FormField>
        <FormField>
          <FormLabel htmlFor="floors">Floors</FormLabel>
          <FormInput
            type="text"
            id="floors"
            {...register('floors', {
              required: true,
            })}
            defaultValue={project?.floors}
          />
          {errors.floors && <Error>Floors is required</Error>}
        </FormField>
        <FormField>
          <FormLabel htmlFor="provisions">Provisions</FormLabel>
          <FormInput
            type="text"
            id="provisions"
            {...register('provisions')}
            defaultValue={project?.provisions}
          />
        </FormField>
        <ActionButton type="submit">Next</ActionButton>
      </form>
    </>
  );
};

const Step3: React.FC<IStepProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const projectForm = useAppSelector((state) => state.admin.projectForm);

  const project = projectForm.project;
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IProject>(); //{ mode: 'all' }

  const onSubmit = (values: IProject) => {
    dispatch(projectFormUpdated(values));
    onNext && onNext();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField>
          <FormLabel htmlFor="email">Structural</FormLabel>
          <FormGroup>
            <FormGroupItem>
              <FormLabel htmlFor="floors">Floors</FormLabel>
              <FormInput
                type="text"
                id="floors"
                {...register('structural.floors', {
                  required: true,
                })}
                defaultValue={project?.structural?.floors}
                isError={!!errors.structural?.floors}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="load">Load</FormLabel>
              <FormInput
                type="text"
                id="load"
                {...register('structural.load')}
                defaultValue={project?.structural?.load}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="type">Type</FormLabel>
              <FormInput
                type="text"
                id="type"
                {...register('structural.type')}
                defaultValue={project?.structural?.type}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="elevation">Elevation</FormLabel>
              <FormInput
                type="text"
                id="elevation"
                {...register('structural.elevation')}
                defaultValue={project?.structural?.elevation}
              />
            </FormGroupItem>
          </FormGroup>
        </FormField>
        <FormField>
          <FormLabel htmlFor="email">Plot Size</FormLabel>
          <FormGroup>
            <FormGroupItem>
              <FormLabel htmlFor="length">Length</FormLabel>
              <FormInput
                type="text"
                id="length"
                {...register('plotSize.length', {
                  required: true,
                })}
                defaultValue={project?.plotSize?.length}
                isError={!!errors.plotSize?.length}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="width">Width</FormLabel>
              <FormInput
                type="text"
                id="width"
                {...register('plotSize.width', {
                  required: true,
                })}
                defaultValue={project?.plotSize?.width}
                isError={!!errors.plotSize?.width}
              />
            </FormGroupItem>
          </FormGroup>
        </FormField>
        <FormField>
          <FormLabel htmlFor="facing">Facing</FormLabel>
          <FormInput
            type="text"
            id="facing"
            {...register('facing', {
              required: true,
            })}
            defaultValue={project?.facing}
          />
          {errors.facing && <Error>Facing is required</Error>}
        </FormField>
        <FormField>
          <FormLabel htmlFor="email">Road Size</FormLabel>
          <FormGroup>
            <FormGroupItem>
              <FormLabel htmlFor="east">East</FormLabel>
              <FormInput
                type="text"
                id="east"
                {...register('roadSize.east')}
                defaultValue={project?.roadSize?.east}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="west">West</FormLabel>
              <FormInput
                type="text"
                id="west"
                {...register('roadSize.west')}
                defaultValue={project?.roadSize?.west}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="north">North</FormLabel>
              <FormInput
                type="text"
                id="north"
                {...register('roadSize.north')}
                defaultValue={project?.roadSize?.north}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="south">South</FormLabel>
              <FormInput
                type="text"
                id="south"
                {...register('roadSize.south')}
                defaultValue={project?.roadSize?.south}
              />
            </FormGroupItem>
          </FormGroup>
        </FormField>
        <FormField>
          <FormLabel htmlFor="email">Setbacks</FormLabel>
          <FormGroup>
            <FormGroupItem>
              <FormLabel htmlFor="setBacks.east">East</FormLabel>
              <FormInput
                type="text"
                id="setBacks.east"
                {...register('setBacks.east')}
                defaultValue={project?.setBacks?.east}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="setBacks.west">West</FormLabel>
              <FormInput
                type="text"
                id="setBacks.west"
                {...register('setBacks.west')}
                defaultValue={project?.setBacks?.west}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="setBacks.north">North</FormLabel>
              <FormInput
                type="text"
                id="setBacks.north"
                {...register('setBacks.north')}
                defaultValue={project?.setBacks?.north}
              />
            </FormGroupItem>
            <FormGroupItem>
              <FormLabel htmlFor="setBacks.south">South</FormLabel>
              <FormInput
                type="text"
                id="setBacks.south"
                {...register('setBacks.south')}
                defaultValue={project?.setBacks?.south}
              />
            </FormGroupItem>
          </FormGroup>
        </FormField>
        <ActionButton type="submit">Next</ActionButton>
      </form>
    </>
  );
};

const Step4: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const projectForm = useAppSelector((state) => state.admin.projectForm);
  const [createProject, { isLoading, isError, isSuccess, error }] =
    useCreateProjectMutation();

  const [
    updateProject,
    {
      isLoading: isUpdateInProgress,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateProjectMutation();

  const [isOpen, setIsOpen] = useState(false);

  const project = projectForm.project;

  const onSubmit = () => {
    if (projectForm.mode === FormMode.EDIT && project) {
      setIsOpen(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createProject({
        ...project,
        projectStarted: false,
      });
    }
  };

  const onConfirmUpdateProject = () => {
    if (project) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateProject({
        project,
        fileNumber: project.fileNumber,
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      toast.success('Operation success');

      dispatch(projectFormCleared());
      navigate('/admin/projects', { replace: true });
    }

    if (isError || isUpdateError) {
      toast.error(
        `Operation failed \n\n ${JSON.stringify(error || updateError)}`
      );
    }
  }, [dispatch, navigate, isSuccess, isUpdateSuccess, isError, isUpdateError]);

  return (
    <>
      <ProjectSummary project={project} />
      <ActionButton
        disabled={isLoading || isUpdateInProgress}
        onClick={onSubmit}
      >
        {projectForm.mode === FormMode.EDIT ? 'Update' : 'Create'} Project{' '}
        {isLoading && <Spinner />}
      </ActionButton>
      <ConfirmDialog
        isOpen={isOpen}
        onCancelClick={() => setIsOpen(false)}
        onConfirmClick={onConfirmUpdateProject}
        isLoading={isUpdateInProgress}
        fileNumber={project?.fileNumber}
      />
    </>
  );
};

const ProjectForm = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const projectForm = useAppSelector((state) => state.admin.projectForm);
  const { data } = useGetProjectsQuery();

  const [currentStep, setCurrentStep] = useState(1);

  const onNext = () => setCurrentStep(currentStep + 1);
  const onPrevious = () => setCurrentStep(currentStep - 1);

  useEffect(() => {
    if (params.fileNumber) {
      if (
        projectForm.status === 'reset' ||
        (projectForm.status === 'init' &&
          projectForm.project?.fileNumber !== params.fileNumber)
      ) {
        const project = data?.Items.find(
          (p) => p.fileNumber === params.fileNumber
        );
        dispatch(
          projectFormInitialised({
            mode: FormMode.EDIT,
            status: 'init',
            project,
          })
        );
      }
    } else {
      if (
        projectForm.status === 'reset' ||
        (projectForm.status === 'init' && projectForm.mode === FormMode.EDIT)
      ) {
        dispatch(
          projectFormInitialised({
            mode: FormMode.CREATE,
            status: 'init',
            project: projectFormDefault.project,
          })
        );
      }
    }
  }, []);

  return (
    <>
      <BreadCrumb
        crumbs={[
          { name: 'Home', path: '/admin' },
          {
            name: `${
              projectForm.mode === FormMode.EDIT ? 'Edit' : 'Create'
            } Project`,
            isCurrent: true,
          },
        ]}
      />
      <FormContainer>
        <StyledH1>
          {projectForm.mode === FormMode.EDIT ? 'Edit' : 'New'} Project Form
        </StyledH1>
        <H2>
          {currentStep > 1 && <BackButton onClick={onPrevious} />}
          {currentStep < 4 && <> Step {currentStep} of 4 </>}
          {currentStep === 4 && 'Summary'}
        </H2>
        {currentStep === 1 && <Step1 onNext={onNext} />}
        {currentStep === 2 && <Step2 onNext={onNext} />}
        {currentStep === 3 && <Step3 onNext={onNext} />}
        {currentStep === 4 && <Step4 />}
      </FormContainer>
    </>
  );
};

export default ProjectForm;
