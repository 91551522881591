import tw, { styled } from 'twin.macro';

import logo from '../../assets/ags-nirman@500.png';
import { IProps } from '../../models/props';
import { SettingsMenu } from './settings-menu';

declare global {
  interface Window {
    $chatwoot: {
      toggleBubbleVisibility: (value: string) => void;
      hasLoaded: boolean;
      setUser: (user: string, userProps: any) => void;
    };
    chatwootSDK: {
      run: (obj: any) => void;
    };
  }
}

const NavContainer = styled.nav`
  ${tw`
    container
    flex
    justify-between
    items-center
  `}
`;

const Logo = styled.img`
  ${tw`
    w-32
    md:w-36
  `}
`;

const ActionContainer = styled.div`
  ${tw`
    flex
    items-center
    gap-2
  `}
`;

export const Header: React.FC<IProps> = ({ children }) => {
  return (
    <header>
      <NavContainer>
        <Logo src={logo} alt="agsnirmaan logo" />
        <ActionContainer>
          {children}
          <SettingsMenu />
        </ActionContainer>
      </NavContainer>
    </header>
  );
};
